.transform{
    -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /* -ms-transform: translateX(50%); */
  /* transform: translateX(50%); */
}

.buttonsnavselected{
    background-color: transparent;
    color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 0px green;
  transition: ease background-color 250ms;
  transition: 0.2s;

}
.buttonsnav{
    background-color: transparent;
    color: white;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 0px 0px green;
  transition: ease background-color 250ms;
  transition: 0.2s;

}
.buttonsnav:hover, .buttonsnavselected:hover {
  
    color: green;
    box-shadow: 0px 0px 6px green;
    border-color: green;
    border-width: thick;

}
